const filterjson = {
  Applicantscategories: [
    { value: "1", label: "pnp" },
    { value: "2", label: "visitor visa" },
    { value: "3", label: "working visa" },
    { value: "4", label: "express entry" },
    { value: "5", label: "business visa" },
  ],
  location: [
    { "country": "afghanistan", "code": "+93" },
    { "country": "aland islands", "code": "+358" },
    { "country": "albania", "code": "+355" },
    { "country": "algeria", "code": "+213" },
    { "country": "american samoa", "code": "+1-684" },
    { "country": "andorra", "code": "+376" },
    { "country": "angola", "code": "+244" },
    { "country": "anguilla", "code": "+1-264" },
    { "country": "antarctica", "code": "+672" },
    { "country": "antigua and barbuda", "code": "+1-268" },
    { "country": "argentina", "code": "+54" },
    { "country": "armenia", "code": "+374" },
    { "country": "aruba", "code": "+297" },
    { "country": "australia", "code": "+61" },
    { "country": "austria", "code": "+43" },
    { "country": "azerbaijan", "code": "+994" },
    { "country": "bahamas", "code": "+1-242" },
    { "country": "bahrain", "code": "+973" },
    { "country": "bangladesh", "code": "+880" },
    { "country": "barbados", "code": "+1-246" },
    { "country": "belarus", "code": "+375" },
    { "country": "belgium", "code": "+32" },
    { "country": "belize", "code": "+501" },
    { "country": "benin", "code": "+229" },
    { "country": "bermuda", "code": "+1-441" },
    { "country": "bhutan", "code": "+975" },
    { "country": "bolivia", "code": "+591" },
    { "country": "bosnia and herzegovina", "code": "+387" },
    { "country": "botswana", "code": "+267" },
    { "country": "bouvet island", "code": "+47" },
    { "country": "brazil", "code": "+55" },
    { "country": "british indian ocean territory", "code": "+246" },
    { "country": "brunei darussalam", "code": "+673" },
    { "country": "bulgaria", "code": "+359" },
    { "country": "burkina faso", "code": "+226" },
    { "country": "burundi", "code": "+257" },
    { "country": "cambodia", "code": "+855" },
    { "country": "cameroon", "code": "+237" },
    { "country": "canada", "code": "+1" },
    { "country": "cape verde", "code": "+238" },
    { "country": "cayman islands", "code": "+1-345" },
    { "country": "central african republic", "code": "+236" },
    { "country": "chad", "code": "+235" },
    { "country": "chile", "code": "+56" },
    { "country": "china", "code": "+86" },
    { "country": "christmas island", "code": "+61" },
    { "country": "cocos (keeling) islands", "code": "+61" },
    { "country": "colombia", "code": "+57" },
    { "country": "comoros", "code": "+269" },
    { "country": "congo", "code": "+242" },
    { "country": "congo, the democratic republic of the", "code": "+243" },
    { "country": "cook islands", "code": "+682" },
    { "country": "costa rica", "code": "+506" },
    { "country": "cote d'ivoire", "code": "+225" },
    { "country": "croatia", "code": "+385" },
    { "country": "cuba", "code": "+53" },
    { "country": "cyprus", "code": "+357" },
    { "country": "czech republic", "code": "+420" },
    { "country": "denmark", "code": "+45" },
    { "country": "djibouti", "code": "+253" },
    { "country": "dominica", "code": "+1-767" },
    { "country": "dominican republic", "code": "+1-809" },
    { "country": "ecuador", "code": "+593" },
    { "country": "egypt", "code": "+20" },
    { "country": "el salvador", "code": "+503" },
    { "country": "equatorial guinea", "code": "+240" },
    { "country": "eritrea", "code": "+291" },
    { "country": "estonia", "code": "+372" },
    { "country": "ethiopia", "code": "+251" },
    { "country": "falkland islands (malvinas)", "code": "+500" },
    { "country": "faroe islands", "code": "+298" },
    { "country": "fiji", "code": "+679" },
    { "country": "finland", "code": "+358" },
    { "country": "france", "code": "+33" },
    { "country": "french guiana", "code": "+594" },
    { "country": "french polynesia", "code": "+689" },
    { "country": "french southern territories", "code": "+262" },
    { "country": "gabon", "code": "+241" },
    { "country": "gambia", "code": "+220" },
    { "country": "georgia", "code": "+995" },
    { "country": "germany", "code": "+49" },
    { "country": "ghana", "code": "+233" },
    { "country": "gibraltar", "code": "+350" },
    { "country": "greece", "code": "+30" },
    { "country": "greenland", "code": "+299" },
    { "country": "grenada", "code": "+1-473" },
    { "country": "guadeloupe", "code": "+590" },
    { "country": "guam", "code": "+1-671" },
    { "country": "guatemala", "code": "+502" },
    { "country": "guernsey", "code": "+44-1481" },
    { "country": "guinea", "code": "+224" },
    { "country": "guinea-bissau", "code": "+245" },
    { "country": "guyana", "code": "+592" },
    { "country": "haiti", "code": "+509" },
    { "country": "heard island and mcdonald islands", "code": "+672" },
    { "country": "holy see (vatican city state)", "code": "+379" },
    { "country": "honduras", "code": "+504" },
    { "country": "hong kong", "code": "+852" },
    { "country": "hungary", "code": "+36" },
    { "country": "iceland", "code": "+354" },
    { "country": "india", "code": "+91" },
    { "country": "indonesia", "code": "+62" },
    { "country": "iran, islamic republic of", "code": "+98" },
    { "country": "iraq", "code": "+964" },
    { "country": "ireland", "code": "+353" },
    { "country": "isle of man", "code": "+44" },
    { "country": "israel", "code": "+972" },
    { "country": "italy", "code": "+39" },
    { "country": "jamaica", "code": "+1-876" },
    { "country": "japan", "code": "+81" },
    { "country": "jersey", "code": "+44-1534" },
    { "country": "jordan", "code": "+962" },
    { "country": "kazakhstan", "code": "+7" },
    { "country": "kenya", "code": "+254" },
    { "country": "kiribati", "code": "+686" },
    { "country": "korea, democratic people's republic of", "code": "+850" },
    { "country": "korea, republic of", "code": "+82" },
    { "country": "kuwait", "code": "+965" },
    { "country": "kyrgyzstan", "code": "+996" },
    { "country": "lao people's democratic republic", "code": "+856" },
    { "country": "latvia", "code": "+371" },
    { "country": "lebanon", "code": "+961" },
    { "country": "lesotho", "code": "+266" },
    { "country": "liberia", "code": "+231" },
    { "country": "libya", "code": "+218" },
    { "country": "liechtenstein", "code": "+423" },
    { "country": "lithuania", "code": "+370" },
    { "country": "luxembourg", "code": "+352" },
    { "country": "macao", "code": "+853" },
    { "country": "madagascar", "code": "+261" },
    { "country": "malawi", "code": "+265" },
    { "country": "malaysia", "code": "+60" },
    { "country": "maldives", "code": "+960" },
    { "country": "mali", "code": "+223" },
    { "country": "malta", "code": "+356" },
    { "country": "marshall islands", "code": "+692" },
    { "country": "martinique", "code": "+596" },
    { "country": "mauritania", "code": "+222" },
    { "country": "mauritius", "code": "+230" },
    { "country": "mayotte", "code": "+262" },
    { "country": "mexico", "code": "+52" },
    { "country": "micronesia (fed. states of)", "code": "+691" },
    { "country": "moldova", "code": "+373" },
    { "country": "monaco", "code": "+377" },
    { "country": "mongolia", "code": "+976" },
    { "country": "montenegro", "code": "+382" },
    { "country": "montserrat", "code": "+1-664" },
    { "country": "morocco", "code": "+212" },
    { "country": "mozambique", "code": "+258" },
    { "country": "myanmar", "code": "+95" },
    { "country": "namibia", "code": "+264" },
    { "country": "nauru", "code": "+674" },
    { "country": "nepal", "code": "+977" },
    { "country": "netherlands", "code": "+31" },
    { "country": "new caledonia", "code": "+687" },
    { "country": "new zealand", "code": "+64" },
    { "country": "nicaragua", "code": "+505" },
    { "country": "niger", "code": "+227" },
    { "country": "nigeria", "code": "+234" },
    { "country": "niue", "code": "+683" },
    { "country": "norfolk island", "code": "+672" },
    { "country": "northern mariana islands", "code": "+1-670" },
    { "country": "norway", "code": "+47" },
    { "country": "oman", "code": "+968" },
    { "country": "pakistan", "code": "+92" },
    { "country": "palau", "code": "+680" },
    { "country": "palestinian territory", "code": "+970" },
    { "country": "panama", "code": "+507" },
    { "country": "papua new guinea", "code": "+675" },
    { "country": "paraguay", "code": "+595" },
    { "country": "peru", "code": "+51" },
    { "country": "philippines", "code": "+63" },
    { "country": "pitcairn", "code": "+64" },
    { "country": "poland", "code": "+48" },
    { "country": "portugal", "code": "+351" },
    { "country": "puerto rico", "code": "+1-787" },
    { "country": "qatar", "code": "+974" },
    { "country": "reunion", "code": "+262" },
    { "country": "romania", "code": "+40" },
    { "country": "russian federation", "code": "+7" },
    { "country": "rwanda", "code": "+250" },
    { "country": "saint barthelemy", "code": "+590" },
    { "country": "saint helena", "code": "+290" },
    { "country": "saint kitts and nevis", "code": "+1-869" },
    { "country": "saint lucia", "code": "+1-758" },
    { "country": "saint martin (french part)", "code": "+590" },
    { "country": "saint pierre and miquelon", "code": "+508" },
    { "country": "saint vincent and the grenadines", "code": "+1-784" },
    { "country": "samoa", "code": "+685" },
    { "country": "san marino", "code": "+378" },
    { "country": "sao tome and principe", "code": "+239" },
    { "country": "saudi arabia", "code": "+966" },
    { "country": "senegal", "code": "+221" },
    { "country": "serbia", "code": "+381" },
    { "country": "seychelles", "code": "+248" },
    { "country": "sierra leone", "code": "+232" },
    { "country": "singapore", "code": "+65" },
    { "country": "sint maarten (dutch part)", "code": "+1-721" },
    { "country": "slovakia", "code": "+421" },
    { "country": "slovenia", "code": "+386" },
    { "country": "solomon islands", "code": "+677" },
    { "country": "somalia", "code": "+252" },
    { "country": "south africa", "code": "+27" },
    { "country": "south georgia and the south sandwich islands", "code": "+500" },
    { "country": "south sudan", "code": "+211" },
    { "country": "spain", "code": "+34" },
    { "country": "sri lanka", "code": "+94" },
    { "country": "sudan", "code": "+249" },
    { "country": "suriname", "code": "+597" },
    { "country": "svalbard and jan mayen", "code": "+47" },
    { "country": "sweden", "code": "+46" },
    { "country": "switzerland", "code": "+41" },
    { "country": "syrian arab republic", "code": "+963" },
    { "country": "taiwan, province of china", "code": "+886" },
    { "country": "tajikistan", "code": "+992" },
    { "country": "tanzania, united republic of", "code": "+255" },
    { "country": "thailand", "code": "+66" },
    { "country": "timor-leste", "code": "+670" },
    { "country": "togo", "code": "+228" },
    { "country": "tokelau", "code": "+690" },
    { "country": "tonga", "code": "+676" },
    { "country": "trinidad and tobago", "code": "+1-868" },
    { "country": "tunisia", "code": "+216" },
    { "country": "turkey", "code": "+90" },
    { "country": "turkmenistan", "code": "+993" },
    { "country": "turks and caicos islands", "code": "+1-649" },
    { "country": "tuvalu", "code": "+688" },
    { "country": "uganda", "code": "+256" },
    { "country": "ukraine", "code": "+380" },
    { "country": "united arab emirates", "code": "+971" },
    { "country": "united kingdom", "code": "+44" },
    { "country": "united states", "code": "+1" },
    { "country": "uruguay", "code": "+598" },
    { "country": "uzbekistan", "code": "+998" },
    { "country": "vanuatu", "code": "+678" },
    { "country": "venezuela", "code": "+58" },
    { "country": "vietnam", "code": "+84" },
    { "country": "wallis and futuna", "code": "+681" },
    { "country": "western sahara", "code": "+212" },
    { "country": "yemen", "code": "+967" },
    { "country": "zambia", "code": "+260" },
    { "country": "zimbabwe", "code": "+263" }
  ],
  category: [
    "house hold",
    "typing",
    "computer operator",
    "teacher",
    "hr",
    "ceo",
    "recepection",
    "frontend developer",
    "backend developer",
    "devops developer",
    "intership",
    "house",
    "doctor",
    "domestic",
    "nurse",
    "other",
    "it",
    "medical",
  ],
  education: [
    "bsc",
    "msc",
    "b. tech",
    "m. tech",
    "bca",
    "bba",
    "mca",
    "bcom",
    "mcom",
    "other",
  ],
  qualification: [
    "grade 10",
    "grade 12",
    "diploma",
    "graduate",
    "post graduate",
    "phd",
    "other",
  ],
  salary: [
    "below 1 lac",
    "1lac-3lac",
    "3lac-5lac",
    "5lac-10lac",
    "above 10lac",
    "not disclosed",
    "other",
  ],
  industry: [
    "retail & wholesale",
    "healthcare",
    "manufacturing",
    "construction, repair & maintenance services",
    "restaurants & food service",
    "finance",
    "human resources & staffing",
    "transportation & logistics",
    "information technology",
    "energy, mining, utilities",
    "management & consulting",
    "non-profit & ngo",
    "education",
    "insurance",
    "government & public administration",
    "telecommunications",
    "aerospace & defence",
    "hotel & travel accommodation",
    "agriculture",
    "legal",
    "real estate",
    "media & communication",
    "arts, entertainment & recreation",
    "pharmaceutical & biotechnology",
    "personal consumer services",
  ],
  experience: [
    "fresher",
    "0-1 ",
    "1-2 ",
    "2-3 ",
    "3-5 ",
    "5-7 ",
    "7+ ",
    "other",
  ],
  job_type: [
    "full-time/permanent",
    // "permanent",
    "part-time/temporary",
    "contract",
    // "temporary",
    "casual",
    "apprenticeship",
    "internship",
    "freelance",
    "local",
    "swep",
    "other",
  ],
  swap: ["yes", "no"],
  employement: ["full-time", "part-time", "other"],
  corporation: ["b2b", "b2c", "c2c", "b2g", "g2c", "other"],
  keyskill: [
    "sales, retail, & customer cupport occupations",
    "healthcare occupations",
    "organization operations & administrative occupations",
    "food & oeverage occupations",
    "technology occupations",
    "finance & accounting occupations",
    "repair, maintenance & installation occupations",
    "architecture & engineering occupations",
    "construction & extraction occupations",
    "supply chain & logistics occupations",
    "manufacturing & utilities occupations",
    "marketing, advertising & public relations occupations",
    "community & social services occupations",
    "education & instruction occupations",
    "transportation occupations",
    "science & research occupations",
    "cleaning & grounds maintenance occupations",
    "media, arts & design occupations",
    "safety, security & civil defence occupations",
    "personal service occupations",
    "travel, attractions & events occupations",
    "legal occupations",
    "farming, fisheries & forestry occupations ",
  ],
  course: [
    "ma",
    "ma",
    "b.com",
    "m.com",
    "ca",
    "mbbs",
    "bds",
    "phd",
    "m.tech",
    "b.tech",
    "bba",
    "mba",
    "cpct",
    "other",
  ],
  specialization: ["maths", "bio", "phy", "social studies", "core", "other"],
  language: [
    "abkhaz",
    "afar",
    "afrikaans",
    "akan",
    "albanian",
    "amharic",
    "arabic",
    "aragonese",
    "armenian",
    "assamese",
    "avaric",
    "avestan",
    "aymara",
    "azerbaijani",
    "bambara",
    "bashkir",
    "basque",
    "belarusian",
    "bengali",
    "bihari",
    "bislama",
    "bosnian",
    "breton",
    "bulgarian",
    "burmese",
    "catalan- valencian",
    "chamorro",
    "chechen",
    "chichewa- chewa- nyanja",
    "chinese",
    "chuvash",
    "cornish",
    "corsican",
    "cree",
    "croatian",
    "czech",
    "danish",
    "divehi- dhivehi- maldivian-",
    "dutch",
    "english",
    "esperanto",
    "estonian",
    "ewe",
    "faroese",
    "fijian",
    "finnish",
    "french",
    "fula- fulah- pulaar- pular",
    "galician",
    "georgian",
    "german",
    "greek, modern",
    "guaraní",
    "gujarati",
    "haitian- haitian creole",
    "hausa",
    "hebrew (modern)",
    "herero",
    "hindi",
    "hiri motu",
    "hungarian",
    "interlingua",
    "indonesian",
    "interlingue",
    "irish",
    "igbo",
    "inupiaq",
    "ido",
    "icelandic",
    "italian",
    "inuktitut",
    "japanese",
    "javanese",
    "kalaallisut, greenlandic",
    "kannada",
    "kanuri",
    "kashmiri",
    "kazakh",
    "khmer",
    "kikuyu, gikuyu",
    "kinyarwanda",
    "kirghiz, kyrgyz",
    "komi",
    "kongo",
    "korean",
    "kurdish",
    "kwanyama, kuanyama",
    "latin",
    "luxembourgish, letzeburgesch",
    "luganda",
    "limburgish",
    "lingala",
    "lao",
    "lithuanian",
    "luba-katanga",
    "latvian",
    "manx",
    "macedonian",
    "malagasy",
    "malay",
    "malayalam",
    "maltese",
    "māori",
    "marathi (marāṭhī)",
    "marshallese",
    "mongolian",
    "mauru",
    "navajo, navaho",
    "norwegian bokmål",
    "north ndebele",
    "nepali",
    "ndonga",
    "norwegian nynorsk",
    "norwegian",
    "suosu",
    "south ndebele",
    "occitan",
    "ojibwe",
    "old church slavonic",
    "oromo",
    "oriya",
    "ossetian, ossetic",
    "panjabi, punjabi",
    "pāli",
    "persian",
    "polish",
    "pashto",
    "portuguese",
    "quechua",
    "romansh",
    "kirundi",
    "romanian",
    "russian",
    "sanskrit",
    "sardinian",
    "sindhi",
    "northern sami",
    "samoan",
    "sango",
    "serbian",
    "scottish gaelic-",
    "shona",
    "sinhala, sinhalese",
    "slovak",
    "slovene",
    "somali",
    "southern sotho",
    "spanish- castilian",
    "sundanese",
    "swahili",
    "swati",
    "swedish",
    "tamil",
    "telugu",
    "tajik",
    "thai",
    "tigrinya",
    "tibetan standard, tibetan, central",
    "turkmen",
    "tagalog",
    "tswana",
    "tonga (tonga islands)",
    "turkish",
    "tsonga",
    "tatar",
    "twi",
    "tahitian",
    "uighur, uyghur",
    "ukrainian",
    "urdu",
    "uzbek",
    "venda",
    "vietnamese",
    "volapük",
    "walloon",
    "welsh",
    "wolof",
    "western frisian",
    "xhosa",
    "yiddish",
    "yoruba",
    "zhuang, chuang",
  ],
  admintype: ["super-admin", "admin", "manager", "executive"],
  duration: ["last week", "last month", "last 3 months", "last year"],
  employee_status: [
    "new",
    "prospect",
    "lead",
    "retained",
    "lost",
    "dead",
    "working on",
    "submitted",
    "completed"
  ],
  interested: [
    "workpermit application",
    "visitor visa",
    "super visa",
    "pnp",
    "spousal open work permit",
    "work permit extension",
    "visitor record",
    "working visa",
    "express entry",
    "business visa",
    "federal PR",
    "pgwp",// new Applicant type added asked by jayant 
    "study permit",// new Applicant type added asked by jayant 
    "temporary resident (visiting , studying , working)",// new Applicant type added asked in doc of Can-46
    "economic immigration",// new Applicant type added asked in doc of Can-46
    "family sponsorship",// new Applicant type added asked in doc Can-46
    "humanitarian and compassionate cases",// new Applicant type added asked in doc Can-46
    "passport",// new Applicant type added asked in doc Can-46
    "citizenship",// new Applicant type added asked in doc Can-46
    "permanent resident cards",// new Applicant type added asked in doc Can-46
    // "swep",
    // "internship",
    // "co-op",
    // "part-time",
    // "full-time",
    // "contract",
    // "all",
  ],
  interested_sub_type: {
    "pnp": [
      "aos",
      "rrs",
      "tourism and hospitality",
      "tech pathway"
    ],
    "temporary resident (visiting , studying , working)": [
      "visitor visa (from outside canada)",
      "visitor visa (from inside canada)",
      "visitor extension (visitor record)",
      "super visa (parents or grandparents)",
      "study permit (from outside canada)",
      "study permit (from inside canada)",
      "study permit extension",
      "work permit from outside canada",
      "work permit from inside canada (initial and extension)",
    ],
    "economic immigration": [
      "atlantic immigration program",
      "canadian experience class",
      "caregivers",
      "provincial nominees",
      "self-employed persons (federal)",
      "quebec business class",
      "skilled workers (federal)",
      "skilled trades (federal)",
      "skilled workers (quebec)",
      "start-up visa",
    ],
    "family sponsorship": [
      "spouse or common-law partner living inside canada",
      "spouse, common-law or conjugal partner living outside canada",
      "dependent child",
      "parents or grandparents",
      "adopted child or other relative"
    ]
  },
  interested_sub_type_of_sub_type: {
    "economic immigration": {
      "caregivers": [
        "home child care provider pilat",
        "home support worker pilot"
      ]
    }
  },
  lmia_status: [
    // "need to start",
    // "job bank requested",
    // "working on it",
    // "additional information required",
    // "application in review",
    // "advertisement running",
    // "application submitted",
    // "payment done",
    // "interview done",
    // "lmia approved",
    // "limia rejected",
    // "lmia partial",
    // "position approved",
    "onboarding",
    "advertisements",
    "documentation",
    "candidate placement",
    "submission",
    "decision",
  ],
  lima_sub_stages: {
    onboarding: [
      "request email id",
      //"contact number",(Old option)
      "Send Retainer Agreement",//(New option)
      "introduction email",
      "job bank creation",
      "intake sheet completion",
    ],
    advertisements: [
      "job bank account",
      //"advertise on job bank and 3 portals."(old option),
      //New options:
      "advertise on job bank",
      "advertise on newcomer hire",
      "advertise on aboriginal hire ",
      "advertise on all-star jobs ",
      "advertise on career owl ",
      "advertise on job spider ",
      "advertise on refugee job board ",

    ],
    documentation: [

      // "Once ads are matured",(old option)
      "request document",//(new option)
      // "Follow up",(old option)
      "follow-up (not received)",//(new option)
      // "birth certificate",
      // "marriage certificate",
      // "education docs",
      // "p c c (all countries)",
      // "upfront Medica",
      // "employment ref letter",
      // "current employment",
      // "digital photo",
      // "passport",
      // "resume/cv",
      // "bank statement",
      // "payslips",
      // "i t r",
      // "i e l t s",
      // "any past refusal",
      // "proof of current immifration status",
      // "biometrics(is given)",
      // "lmia, job offer, job contact will be arrange by us"
    ],
    "candidate placement": [
      "confirm status of candidates",
      "attain information on candidate",
      "employer's work permit plans",
    ],
    "submission": [
      //old options:
      // "initiate  l m i a application on portal",
      // "fill job details and docs from employer",
      // "attach relevant documents",
      // "request harpreet Ma'am's review",
      // "attest and submit on portal",
      // "await payment request",
      // "inform employer of payment and potential interview",
      //new options:
      "start preparing application",
      "request harpreet’s Review",
      "initiate LMIA application on portal",
      "application submitted",
      "payment request",
      "payment made",
    ],
    "decision": [
      // "awaiting decision",
      // "approved",
      // "rejected",
      //New Json given by client
      // "new",(Client ask to remove)
      //"submitted",(Client ask to remove)
      "approved",
      "refused",
      // "maintain mature ads throughout the process",
      // "initiate app after ads mature",
      // "email employer for updates",
      // "request extra docs if needed",
      // "keep them updated on app status",
    ],
  },
  visa_status: [
    "onboard",
    "documentation",
    "file preparation",
    "file review",
    "file submission",
    "file decision",
  ],
  visa_sub_stages: {
    onboard: ["sending a document checklist", "ra invoice processing"],
    documentation: [
      "birth certificate",
      "marriage certificate",
      "education docs",
      "pcc (all countries)",
      "upfront Medicaleee",
      "employment ref letter",
      "current employment",
      "digital photo",
      "passport",
      "resume/cv",
      "bank statement",
      "payslips",
      "itr",
      "ielts/pte",
      "any past refusal",
      "proof of current Immigration status",
      "biometrics (is given)",
      "lmia, job offer, job contact will be arrange by us",
    ],
    "file preparation": [
      "reviewing documents",
      "i m m 1295",
      "i m m 5476",
      "i m m 5645",
      "representative submission letter",
    ],
    "file review": ["create account on authorized representative portal"],
    "file decision": ["awaiting decision", "approved", "rejected"],
  },
  canadian_candidate_work_status: [
    "Visitor",
    "Student",
    "Post Grad Work Permit",
    "Open Work Permit",
    "LMIA Closed Work permit",
    "Spousal Open Work Permit",
  ],
  Rerainer_Agreement_subCategories: [
    "visitor",
    "study",
    "work permit",
    "post graduation work permit",
    "temporary resident visa",
    "prospective workers",
    "express entry",
    "PNP + express entry/federal PR",
    "super visa application",
    "spousal sponsorship",
    "citizenship",
    "PR card renewal",
    "ATIP",
    "permanent residency travel document",
    "employers",
    "LMIA exempt employers",
    "initial consultation",
    "recruitment services agreement"
  ]
  ,
  "program_discipline_category": [
    "Engineering and Technology",
    "Law",
    "Politics",
    "Social",
    "Community Service and Teaching",
    "Business",
    "Management and Economics",
    "Arts",
    "English for Academic Studies",
    "Sciences",
    "Health Sciences",
    "Medicine",
    "Nursing",
    "Paramedic and Kinesiology",
    "Arts/Sciences",
    "Science",
    "Management and Economic",
    "Technology"
  ],
  "program_discipline_sub_category": [
    "Architecture",
    "Biomedical Engineering",
    "Chemical Engineering",
    "Civil Engineering",
    "Construction",
    "Electrical Engineering",
    "Electronic",
    "Environmental Engineering",
    "Teaching",
    "Early Development",
    "Child Care",
    "Management",
    "Administration",
    "General",
    "Accounting",
    "Community",
    "Social Service",
    "Human Resources",
    "Entrepreneurship",
    "Finance",
    "Economics",
    "International Business",
    "Biochemistry",
    "Biology",
    "Chemistry",
    "Communication",
    "Fashion",
    "Esthetics",
    "Veterinarian",
    "Environmental",
    "Earth Sciences",
    "Marketing",
    "Analyst",
    "Advertising",
    "Mechanical",
    "Manufacturing",
    "Robotic Engineering",
    "Graphic Design",
    "Interior Design",
    "Technology",
    "Software",
    "Computer",
    "IT",
    "Media",
    "Photography",
    "Film",
    "Theatre",
    "Performance",
    "Psychology",
    "Philosophy",
    "Therapy",
    "Material Engineering",
    "Geology",
    "English Literature",
    "Law",
    "Politics",
    "Police",
    "Security",
    "Sociology/Psychology",
    "Food and Culinary",
    "Humanitarian Sciences",
    "Game Design",
    "Game Animation",
    "Game Creation",
    "Nutrition",
    "Exercise",
    "Animation/Graphic Design",
    "History",
    "Pharmacy",
    "Animation",
    "Supply Chain",
    "Hospitality and Tourism",
    "Recreation",
    "Sociology",
    "Mathematics",
    "Computer Science",
    "Industrial",
    "Astronomy",
    "Physics",
    "Anthropology",
    "Fine Arts",
    "Dental",
    "Music",
    "Audio",
    "Political",
    "Global Studies",
    "Planning",
    "Gender Studies",
    "Languages",
    "Public Relation",
    "Geography",
    "Optometry",
    "Religion",
    "Environment",
    "Social Services",
    "Communications",
    "Humanitarian Sciences"
  ],
  "programs_english_requirement": [
    "50%",
    "55%",
    "60%",
    "65%",
    "70%",
    "N/A"
  ],
  "programs_math_requirement": [
    "50%",
    "51%",
    "55%",
    "60%",
    "65%",
    "70%",
    "72%",
    "Grade A",
    "N/A"
  ],
  "programs_education_entry_criteria": [
    "Grade 10",
    "Grade 11",
    "Grade 12",
    "High School",
    "2-Year Undergraduate Diploma",
    "3-Year Undergraduate Diploma",
    "3-Year Undergraduate Advanced Diploma",
    "3-Year Bachelor's Degree",
    "4-Year Bachelor's Degree",
    "1-Year Post-Secondary Certificate"
  ],
  "programs_gpa_entry_criteria": [
    "0",
    "7",
    "50",
    "52",
    "55",
    "57",
    "60",
    "62",
    "63",
    "64",
    "65",
    "67",
    "70",
    "72",
    "73",
    "74",
    "75",
    "77",
    "78",
    "80",
    "82",
    "85",
    "88",
    "90",
    "91",
    "100"
  ],
  "programs_ilets_entry_criteria": [
    "3.5",
    "4",
    "4.5",
    "5",
    "5.5",
    "6",
    "6.5",
    "7",
    "7.5"
  ],
  "programs_intake_month": [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January - November",
    "12 months"
  ]

};
export default filterjson;
